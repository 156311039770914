// MISSING
export const AccountNumber = 'Account Number is required'
export const Action = 'Action is required'
export const ApplicationId = 'Application ID is required'
export const Comments = 'Comments is required'
export const CustomerId = 'Customer ID is required'
export const EmailAddress = 'Customer email is required'
export const EmailId = 'Email ID is required'
export const EmployeeId = 'Employee ID is required'
export const LocationId = 'Location ID is required'
export const OwnerId = 'Owner ID is required'
export const PartnerId = 'Partner ID is required'
export const PartnerName = 'Partner NAME is required'
export const PolicyNumber = 'Policy Number is required'
export const ProductId = 'Product ID required'

// TYPES
export const Number = 'The value should be a number'

// GENERAL
export const ClipboardNotSupported =
  'useClipboard: navigator.clipboard is not supported'
